import React, { useEffect } from 'react'
import styles from './style.module.scss'
import IconoGraphy from '@/components/Common/IconoGraphy'
import { useClickOutsideListenerRef } from '@/utils/useClickOutsideListener'
import MobileBackHeader from '../MobileBackHeader'
import { useMediaQuery } from 'react-responsive'

function Popup({
  children,
  title,
  onClose,
  currentScreen,
  id = '',
  hideCloseButton = false,
  isFullHeight = false,
  customTitleClass = '',
  isZeroPadding = false,
  customClass = '',
  isFullPopup = false,
  isFullWidth = false,
  isSpaceOutside = false,
  showBack,
  isInnerPopup,
  customModalBodyClass,
  customModalTitleClass,
  onBackClicked,
  isAllPropertiesFullPopup = false,
  isConfirmPopup = false,
  showDeskTopBackWithText=false,
  isLoginSuccessScreen = false,
  isCustomClass = false,
  isTCModalBody = false,
  isScrollBarClass = false,
  istncCustomClass = false,
  sadadInvoiceClose=false,
  tcCustomBodyClass,
  isRentalDetails = false,
  positionProp,
  isDepositPopup= false,
  isOutClickedAllowed= true,
  historyModal=false,
  isLoginPadding = false,
  isFromBidderCapacity = false,
  showNewCustomClose = false
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const rentalFontClass = isRentalDetails && !isMobile ? 'f_24' : 'f_8'
  const fontClass = isDepositPopup ? 'f_10': rentalFontClass
  const sadadScreen = currentScreen ==='generatedSADADinvoice'

  const handlePopupClose = () => {
    if(currentScreen!=='login' && currentScreen!=='otp' && currentScreen!=='loginSuccess'){
    const scrollNode = document.getElementsByClassName('infinite-scroll-component')
    if(isMobile && scrollNode && scrollNode.length > 0) {
       document.getElementsByClassName('infinite-scroll-component')[0].style.overflow = 'auto';
    }
    onClose()
  }
  }
  const refPopup = isOutClickedAllowed ? useClickOutsideListenerRef(handlePopupClose) : null

	useEffect(() => {
		document.body.style.overflow = 'hidden'
		if(isMobile) {
			document.body.style.position ='fixed'
		}
		return () => {
			if(isInnerPopup) return;
			document.body.style.overflow = 'auto'
			document.body.style.position ='inherit'
      {positionProp && window.scrollTo({top: positionProp, behavior: 'smooth'})}
		}
	}, [])

  const goBackHandler = onBackClicked ? onBackClicked : onClose;
  const sadadPopupClassName = sadadInvoiceClose ? styles.sadad_popup_close : '';

  const getModalClassName = () => {
    let className = '';
  
    className += isRentalDetails ? styles.rentalModalBox : styles.modalBox;
    if (istncCustomClass) className += ` ${styles.tncCustomClass}`;
    if (isScrollBarClass) className += ` ${sadadScreen ? styles.sadadScrollerBarBox : styles.scrollerBarBox}`;
    if (isFullHeight) className += ` ${styles.mCloseAuction}`;
    if (customClass) className += ` ${styles[customClass]}`;
    if (isFullPopup) className += ` ${styles.fullPopup}`;
    if (isAllPropertiesFullPopup) className += ` ${styles.allPropertiesFullPopup}`;
    if (isCustomClass) className += ` ${styles.customWidth}`;
    if (isFullWidth) className += ` ${styles.fullWidth}`;
    if (isSpaceOutside) className += ` ${styles.spaceOutside}`;
    if(isFromBidderCapacity) className += ` ${styles.bidderCapacityPopup}`
    return className.trim();
  };

  const getModalBodyClassName = () => {
    let className = styles.modalBody; // Always include the base class
  
    if (isRentalDetails) className += ` ${styles.rentalModalBody}`;
    if (isTCModalBody) className += ` ${styles.tCModalbody}`;
    if (tcCustomBodyClass) className += ` ${styles[tcCustomBodyClass]}`;
    if (customModalBodyClass) className += ` ${customModalBodyClass}`;
    if (isAllPropertiesFullPopup) className += ` ${styles.allPropertiesFullPopup}`;
    if (isZeroPadding) className += ` ${styles.noSpace}`;
    if (sadadScreen) className += ` ${styles.modalBodySadadInvoiceScreen}`;
    if(isFromBidderCapacity) className += ` ${styles.backgroundChange}`;
    return className.trim(); 
  };

  return (
    <div
      className={`${styles.modelOverlay} ${isLoginPadding ? styles.beforeLogin : ""} ${isInnerPopup && styles.innerPopup} ${historyModal ? styles.historyModal : ''}`}
      id={id}
      style={isConfirmPopup ? { maxHeight: '100%' } : { maxHeight: '100vh' }}
    >
      <div
        className={getModalClassName()}
        id={`popup_modalBox`}
        ref={refPopup}
      >
        {(showBack && isMobile) || showDeskTopBackWithText ? (
          <MobileBackHeader
            title={title}
            goBack={goBackHandler}
            isLoginSuccessScreen={isLoginSuccessScreen}
            showDeskTopBackWithText={showDeskTopBackWithText}
          />
        ) : (
          !historyModal ? 
          <div
            className={`${styles.HeaderTitle} ${customModalTitleClass}  ${styles[customTitleClass]} ${
              isRentalDetails && styles.rentalHeaderTitle
            } ${isFromBidderCapacity? styles?.popupDocHeader : ''}`}
          >
            {showNewCustomClose && (
              <div
                className={`${styles.crossIcon} ${sadadPopupClassName}`}
                onClick={(el) => {
                  el.stopPropagation()
                  document.body.style.overflow = 'auto'
                  onClose()
                }}
              >
                <IconoGraphy
                  iconClass={`icon-close`}
                  iconColor={'color_revamp_close_icon'}
                  fontSize={fontClass}
                ></IconoGraphy>
              </div>
            )}
            <h2>{title}</h2>
            {!hideCloseButton && !showNewCustomClose && (
              <div
                className={`${styles.crossIcon} ${sadadPopupClassName}`}
                onClick={(el) => {
                  el.stopPropagation()
                  document.body.style.overflow = 'auto'
                  onClose()
                }}
              >
                <IconoGraphy
                  iconClass={`icon-close`}
                  iconColor={'color_revamp_close_icon'}
                  fontSize={fontClass}
                ></IconoGraphy>
              </div>
            )}
          </div> : ''
        )}
        <div
          className={getModalBodyClassName()}
          id='scrollableDiv'
        >
          {children}
        </div>
      </div>
    </div>
  )
}
export default Popup
